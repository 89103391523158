import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Header from "../components/Header";
import axios from "axios";
import { Helmet } from "react-helmet";


// let google = window.google;

// function myMap(coordsArray) {
//     var myLatlng = new google.maps.LatLng(coordsArray[0], coordsArray[1]);

//     var mapProp = {
//         center: myLatlng,
//         zoom: 12,
//     };

//     var map = new google.maps.Map(document.getElementById("googleMap"), mapProp);

//     var marker = new google.maps.Marker({
//         position: myLatlng,
//     });

//     marker.setMap(map);
// }

export default function Contact(props) {

    const [successMessage, setSuccessMessage] = useState();
    const [contactDetails, setContactDetails] = useState();
    const [formLoading, setFormLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        window.triggerScroll();

        axios.post('contact-details').then(response => {
            setContactDetails(response.data);
            window.triggerScroll();
        });
    }, []);

    const contactSubmit = (e) => {
        e.preventDefault();

        setSuccessMessage('');
        setErrorMessages([]);
        setFormLoading(true);

        axios.post('contact-form', {
            "name": name,
            "email": email,
            "phone": phone,
            "subject": subject,
            "message": message
        })
            .then((r) => {
                setName('');
                setEmail('');
                setPhone('');
                setSubject('');
                setMessage('');
                setSuccessMessage(r.data);
            })


            .catch((r) => {
                let newErrorMessages = [];
                Object.keys(r.response.data.errors).forEach(errorInput => {
                    r.response.data.errors[errorInput].forEach(errorMessage => {
                        newErrorMessages.push(errorMessage)
                    });
                });
                setErrorMessages(newErrorMessages);
            })

            .finally(() => {
                setFormLoading(false);
            });
    }

    return (
        <Layout generalData={props.generalData} setActiveAboutSection={props.setActiveAboutSection} activePage="contact">
            <Helmet>
                <title>{props.seoPage.title}</title>
                <meta name="title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta name="description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta property="og:description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="og:image" content={props.seoPage.image_full_path} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta property="twitter:description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="twitter:image" content={props.seoPage.image_full_path} />
            </Helmet>
            <Header
                title={props.generalData.contactUsSettings.page_title}
            />

            <Banner
                image={props.generalData.contactUsSettings.banner_image_path}
                title={props.generalData.contactUsSettings.banner_title}
            />

            {/* {
                contactDetails ?
                    <div className="container contact-details pt-lg-5">
                        <div className="row py-5 px-lg-0 px-2 gx-lg-5">
                            {
                                contactDetails.map(contactIcon => (
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-lg-0 mb-5" animate="down">
                                        <img className="contactus-icons" src={contactIcon.icon} alt="icon" />
                                        <h4 className="mt-4">{contactIcon.title}</h4>
                                        <div className="contact-details" dangerouslySetInnerHTML={{ __html: contactIcon.content }} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    :

                    <div className="lds-ellipsis mx-auto">
                        <div></div>
                        <div></div>
                    </div>

            } */}


            <div className="container pt-5">
                <div className="row py-lg-5">
                    <div className="col-lg-7 col-md-12 gx-5 px-lg-0 px-3" animate="right">
                        {/* <div id="googleMap"></div> */}

                        {
                            contactDetails ?
                                <div className="contact-details">
                                    <div className="row ">
                                        {
                                            contactDetails.map(contactIcon => (
                                                <div className="col-lg-5 col-md-6 col-sm-6 col-12 mb-lg-0 mb-4" animate="down">
                                                    <img className="contactus-icons" src={contactIcon.icon} alt="icon" />
                                                    <h4 className="my-4">{contactIcon.title}</h4>
                                                    {contactIcon.slug === 'find-us-on-social-media' ?


                                                        props.generalData.socialChannels.map(icon => (
                                                            <a target="_blank" rel="noreferrer" href={icon.link} key={icon.id}>
                                                                <img className="social-icon black-icon me-3" src={icon.icon_path} alt="social-icon" />
                                                            </a>
                                                        ))
                                                        :
                                                        <div className="contact-details" dangerouslySetInnerHTML={{ __html: contactIcon.content }} />
                                                    }

                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                :

                                <div className="lds-ellipsis mx-auto">
                                    <div></div>
                                    <div></div>
                                </div>

                        }

                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-4 col-sm-12" >
                        <h1 className="mb-lg-2 mb-3 mt-lg-0 mt-2 px-lg-0 px-2" animate="left">{props.generalData.contactUsSettings.form_title}</h1>
                        <p className="mb-lg-3 mb-4 px-lg-0 px-2" animate="left">{props.generalData.contactUsSettings.form_description}</p>
                        <form onSubmit={contactSubmit}>
                            <div className="row px-lg-0 px-2" animate="left">
                                <div className="col-lg-6 col-md-12 p-lg-1">
                                    <input className="mb-3" type="text" placeholder={props.generalData.contactUsSettings.name_placeholder} value={name} onChange={e => setName(e.target.value)} />
                                    <input className="mb-3 " type="number" placeholder={props.generalData.contactUsSettings.phone_placeholder} value={phone} onChange={e => setPhone(e.target.value)} />
                                </div>
                                <div className="col-lg-6 col-md-12 p-lg-1">
                                    <input className="mb-3" type="mail" placeholder={props.generalData.contactUsSettings.email_placeholder} value={email} onChange={e => setEmail(e.target.value)} />
                                    <input className="mb-3" type="text" placeholder={props.generalData.contactUsSettings.subject_placeholder} value={subject} onChange={e => setSubject(e.target.value)} />
                                </div>
                                <div className="col-12 p-lg-1">
                                    <textarea className="mb-3" cols="30" rows="8" placeholder={props.generalData.contactUsSettings.message_placeholder} onChange={e => setMessage(e.target.value)} value={message} />
                                </div>
                                <div className="col-12 p-lg-1">
                                    <button type="submit" className="contact-submit">
                                        {
                                            formLoading ?
                                                'Loading...'
                                                :
                                                props.generalData.contactUsSettings.button_label
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row justify-content-end py-4">
                    <div className="col-lg-4">
                        <div className={(errorMessages.length ? "" : "d-none")}>

                            {
                                errorMessages.map(errorMessage => (
                                    <p className="mb-1">{errorMessage}</p>
                                ))
                            }
                        </div>

                        <div className={(successMessage ? "" : "d-none")}>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <p className="mb-1">{successMessage}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    )
}

