import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import PatientTools from "./pages/PatientTools";
import Publications from "./pages/Publications";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import FindaGeneticCounselor from "./pages/FindaGeneticCounselor";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import ScrollToTop from "./components/ScrollToTop";
import axios from "axios";

axios.defaults.baseURL = 'https://test.asgcsociety.org/api/';

export default function App() {

    const [generalData, setGeneralData] = useState();
    const [activeAboutSection, setActiveAboutSection] = useState();

    useEffect(() => {
        axios.post('general-data').then(response => {
            setGeneralData(response.data);
        });
    }, []);

    useEffect(() => {
        if (generalData) {
            window.triggerScroll();
        }
    }, [generalData]);

    if (!generalData) return null;

    return (
        <Router>
            {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
            <Fragment>
                <ScrollToTop />
                <Switch>
                    <Route path="/terms">
                        <Terms generalData={generalData} setActiveAboutSection={setActiveAboutSection} seoPage={generalData.seo_pages['terms']}/>
                    </Route>
                    <Route path="/contact">
                        <Contact generalData={generalData} setActiveAboutSection={setActiveAboutSection} seoPage={generalData.seo_pages['contact-us-aagc']} />
                    </Route>
                    <Route path="/find-a-genetic-counselor">
                        <FindaGeneticCounselor generalData={generalData} setActiveAboutSection={setActiveAboutSection} seoPage={generalData.seo_pages['find-a-genetic-counselor-aagc']} />
                    </Route>
                    <Route path="/blog/:slug">
                        <BlogPost generalData={generalData} setActiveAboutSection={setActiveAboutSection} />
                    </Route>
                    <Route path="/blog" >
                        <Blog generalData={generalData} setActiveAboutSection={setActiveAboutSection} seoPage={generalData.seo_pages['blog-aagc']} />
                    </Route>
                    <Route path="/publications">
                        <Publications generalData={generalData} setActiveAboutSection={setActiveAboutSection} seoPage={generalData.seo_pages['publications-aagc']}/>
                    </Route>
                    <Route path="/patient-tools" >
                        <PatientTools generalData={generalData} setActiveAboutSection={setActiveAboutSection} seoPage={generalData.seo_pages['patient-tools-aagc']} />
                    </Route>
                    <Route path="/about" exact={true}>
                        <About generalData={generalData} activeAboutSection={activeAboutSection} setActiveAboutSection={setActiveAboutSection} seoPage={generalData.seo_pages['about-us-aagc']} />
                    </Route>
                    <Route path="/">
                        <Home generalData={generalData} setActiveAboutSection={setActiveAboutSection} seoPage={generalData.seo_pages['home-aagc']} />
                    </Route>
                </Switch>
            </Fragment>
        </Router>
    );
}


