import Layout from "../components/Layout";
import Header from "../components/Header";
import Banner from "../components/Banner";
import { Helmet } from "react-helmet";

export default function Terms(props) {
    return (
        <Layout generalData={props.generalData} setActiveAboutSection={props.setActiveAboutSection} activePage="terms">
             <Helmet>
                <title>{props.seoPage.title}</title>
                <meta name="title" content={props.seoPage.title} />
                <meta name="description" content={props.seoPage.description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={props.seoPage.title} />
                <meta property="og:description" content={props.seoPage.description} />
                <meta property="og:image" content={props.seoPage.image_full_path} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={props.seoPage.title} />
                <meta property="twitter:description" content={props.seoPage.description} />
                <meta property="twitter:image" content={props.seoPage.image_full_path} />
            </Helmet>
            <Header
                title={props.generalData.websiteSettings.terms_title}
            />

            <Banner
                image={props.generalData.websiteSettings.terms_banner_image_path}
                title={props.generalData.websiteSettings.terms_banner_title}
            />
            <div className="container py-5">
                <div dangerouslySetInnerHTML={{ __html: props.generalData.websiteSettings.content }} />
            </div>
        </Layout>
    )
}