import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { animateScroll } from "react-scroll";

export default function Layout(props) {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [instaFeeds, setInstaFeeds] = useState([]);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [smallHeader, setSmallHeader] = useState(false);

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {
        // if (touchStart - touchEnd > 150) {
        // }

        if (touchStart - touchEnd < -150) {
            setSidebarOpen(false);
        }
    }

    function menuClick() {
        setSidebarOpen(true);
    }

    function closeMenu() {
        setSidebarOpen(false);
    }

    function dropdownSlide() {
        setDropdownOpen(!dropdownOpen);
    }

    useEffect(() => {
        axios.get('insta').then(response => {
            setInstaFeeds(response.data);
        });

        window.addEventListener('scroll', function (e) {
            setSmallHeader(window.pageYOffset > 100);
        });
    }, []);

    useEffect(() => {
        document.querySelector('body').style.overflow = sidebarOpen ? 'hidden' : null;
        document.querySelector('html').style.overflow = sidebarOpen ? 'hidden' : null;
    }, [sidebarOpen]);

    return (
        <>
            <div className="position-relative">
                <div className={"nav-bar no-transition-delay fixed-top" + (smallHeader ? " small" : "")}>
                    <div className="container h-100">
                        <div className="row h-100 justify-content-lg-center justify-content-between">
                            <div className="col-auto h-100 py-2">
                                <Link to="/">
                                    <img className="logo-header h-100" src="/assets/images/logo.svg" alt="logo" />
                                </Link>
                            </div>
                            <div className="col align-self-center justify-content-center align-items-center menu-items d-none d-lg-flex">
                                <div className={(props.activePage === "home" ? "menu-active" : "") + " menu-hover"}>
                                    <Link to={"/"} className="mx-4">{props.generalData.websiteSettings.home_menu_label}</Link>
                                    <img className="dots" src="/assets/images/dots.svg" alt="" />
                                </div>

                                <div className="dropdown">
                                    <div className={(props.activePage === "about" ? "menu-active" : "") + " menu-hover"}>
                                        <Link to={"/about"} className="mx-4">{props.generalData.websiteSettings.about_menu_label}</Link>
                                        <img className="dots" src="/assets/images/dots.svg" alt="" />
                                    </div>
                                    <div className="dropdown-content">
                                        {/* {
                                            props.activePage == "about" ?
                                                <>
                                                    <div className="p-2" >
                                                        <span onClick={() => props.setActiveAboutSection('mission')}>
                                                            {props.generalData.websiteSettings.mission_menu_label}
                                                        </span>
                                                    </div>
                                                    <div className="line-dropdown"></div>
                                                    <div className="p-2">
                                                        <span onClick={() => props.setActiveAboutSection('team')}>
                                                            {props.generalData.websiteSettings.team_menu_label}
                                                        </span>
                                                    </div>
                                                </>
                                                : */}
                                                <>
                                                    <div className="p-2" >
                                                        <Link to={"/about"} onClick={(e) => {
                                                            if (props.activePage == "about") e.preventDefault();
                                                            props.setActiveAboutSection('mission')
                                                        }}>
                                                            {props.generalData.websiteSettings.mission_menu_label}
                                                        </Link>
                                                    </div>
                                                    <div className="line-dropdown"></div>
                                                    <div className="p-2">
                                                        <Link to={"/about"} onClick={(e) => {
                                                            if (props.activePage == "about") e.preventDefault();
                                                            props.setActiveAboutSection('team')
                                                        }}>
                                                            {props.generalData.websiteSettings.team_menu_label}
                                                        </Link>
                                                    </div>
                                                </>
                                        {/* } */}
                                    </div>
                                </div>


                                <div className={(props.activePage === "find-a-genetic-counselor" ? "menu-active" : "") + " menu-hover"}>
                                    <Link to={"/find-a-genetic-counselor"} className="mx-4">{props.generalData.websiteSettings.genetic_counselor_menu_title}</Link>
                                    <img className="dots" src="/assets/images/dots.svg" alt="" />
                                </div>
                                <div className="dropdown">
                                    <div className={(props.activePage === "resources" ? "menu-active" : "") + " menu-hover"}>
                                        <Link to="#" className="mx-4">{props.generalData.websiteSettings.ressources_menu_title}</Link>
                                        <img className="dots" src="/assets/images/dots.svg" alt="" />
                                    </div>
                                    <div className="dropdown-content">

                                        <div className="p-2" >
                                            <Link to="/patient-tools">
                                                {props.generalData.websiteSettings.patient_tools_menu_title}
                                            </Link>
                                        </div>
                                        <div className="line-dropdown"></div>
                                        <div className="p-2">
                                            <Link to="/publications">
                                                {props.generalData.websiteSettings.publications_menu_title}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className={(props.activePage === "blog" ? "menu-active" : "") + " menu-hover"}>
                                    <Link to="/blog" className="mx-4">{props.generalData.websiteSettings.blog_menu_title}</Link>
                                    <img className="dots" src="/assets/images/dots.svg" alt="" />
                                </div>
                                <div className={(props.activePage === "contact" ? "menu-active" : "") + " menu-hover"}>
                                    <Link to="/contact" className="mx-4">{props.generalData.websiteSettings.contact_us_menu_title}</Link>
                                    <img className="dots" src="/assets/images/dots.svg" alt="" />
                                </div>
                            </div>
                            <div className="col-auto align-self-center">
                                <svg onClick={menuClick} className="menu-burger h-auto " data-name="Component 63 – 13" xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 25 20">
                                    <rect className="stroke-w" id="Rectangle_198" data-name="Rectangle 198" width="25" height="3" fill="#fff" />
                                    <rect className="stroke-w" id="Rectangle_199" data-name="Rectangle 199" width="25" height="3" transform="translate(0 8)" fill="#fff" />
                                    <rect className="stroke-w" id="Rectangle_200" data-name="Rectangle 200" width="25" height="3" transform="translate(0 16)" fill="#fff" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {sidebarOpen ?
                <svg className="close-menu-mobile d-block d-lg-none" xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" onClick={closeMenu}>
                    <path id="Path_14" data-name="Path 14" d="M41,0A41,41,0,1,1,0,41,41,41,0,0,1,41,0Z" transform="translate(82 82) rotate(180)" fill="#fff" />
                    <g id="Group_64" data-name="Group 64" transform="translate(0.001 -0.118)">
                        <path className="stroke-w" id="Path_57" data-name="Path 57" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(810.387 636.944) rotate(180)" fill="none" stroke="#4b77a6" strokeMiterlimit="10" strokeWidth="4" />
                        <path className="stroke-w" id="Path_58" data-name="Path 58" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(-728.389 -554.708)" fill="none" stroke="#4b77a6" strokeMiterlimit="10" strokeWidth="4" />
                    </g>
                </svg>
                :
                null
            }
            <div className={"sidebar-menu " + (sidebarOpen ? "" : "hide")} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                <div className="row justify-content-end h-100" >
                    <div className="col-lg-5 col-10 col-md-6">
                        <div className="background-sidebar h-100">
                            <div className="row py-lg-5 py-4">
                                <div className="row justify-content-end text-end h-100 d-block d-lg-none ">
                                    {/* <svg className="close-menu" xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" onClick={closeMenu}>
                                        <path id="Path_14" data-name="Path 14" d="M41,0A41,41,0,1,1,0,41,41,41,0,0,1,41,0Z" transform="translate(82 82) rotate(180)" fill="#fff" />
                                        <g id="Group_64" data-name="Group 64" transform="translate(0.001 -0.118)">
                                            <path className="stroke-w" id="Path_57" data-name="Path 57" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(810.387 636.944) rotate(180)" fill="none" stroke="#4b77a6" strokeMiterlimit="10" strokeWidth="4" />
                                            <path className="stroke-w" id="Path_58" data-name="Path 58" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(-728.389 -554.708)" fill="none" stroke="#4b77a6" strokeMiterlimit="10" strokeWidth="4" />
                                        </g>
                                    </svg> */}
                                </div>

                                <div className="col-lg-7 col-md-12 col-sm-10 col-12">
                                    <div className="container mx-lg-4 pt-5">
                                        <div className="d-block d-lg-none">
                                            <div className="mobile-menu">
                                                <Link to="/" >{props.generalData.websiteSettings.home_menu_label}</Link>
                                            </div>
                                            <div className="mobile-menu pt-3">
                                                <Link to="/about" >{props.generalData.websiteSettings.about_menu_label}</Link>
                                            </div>
                                            <div className="mobile-menu pt-3">
                                                <Link to="/find-a-genetic-counselor">{props.generalData.websiteSettings.genetic_counselor_menu_title}</Link>
                                            </div>
                                            <div className="dropdown-item-menu mobile-menu pt-3">
                                                <Link to="#" onClick={dropdownSlide}>{props.generalData.websiteSettings.ressources_menu_title}</Link>
                                                <div className={"dropdown-sidebar " + (dropdownOpen ? "" : "remove")} >
                                                    <div className="dropdown-sidebar-content ms-3 pt-3">
                                                        <Link to="/patient-tools"  >
                                                            <b>{props.generalData.websiteSettings.patient_tools_menu_title}</b>
                                                        </Link>
                                                    </div>
                                                    <div className="line-dropdown"></div>
                                                    <div className="dropdown-sidebar-content ms-3 pt-3">
                                                        <Link to="/publications" >
                                                            <b> {props.generalData.websiteSettings.publications_menu_title} </b>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mobile-menu pt-3">
                                                <Link to="/blog" >{props.generalData.websiteSettings.blog_menu_title}</Link>
                                            </div>
                                            <div className="mobile-menu pt-3">
                                                <Link to="/contact" >{props.generalData.websiteSettings.contact_us_menu_title}</Link>
                                            </div>
                                            <hr className="menu-sep my-5" />
                                        </div>

                                        <img className="side-logo" src={props.generalData.websiteSettings.side_menu_logo_path} alt="" />
                                        <div className="pb-lg-5 pb-md-5">
                                            <p className="mt-4 mb-5">{props.generalData.websiteSettings.side_menu_description}</p>
                                        </div>
                                        <div className="flex-wrap mb-4">
                                            {
                                                props.generalData.socialChannels.map(icon => (
                                                    <a target="_blank" rel="noreferrer" href={icon.link} key={icon.id}>
                                                        <img className="social-icon me-3" src={icon.icon_path} alt="social-icon" />
                                                    </a>
                                                ))
                                            }

                                        </div>
                                        <div className="row g-0 gallery mt-lg-5 mt-md-5">
                                            {
                                                instaFeeds ?
                                                    instaFeeds.map(instaFeed => (
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-3" key={instaFeed.id}>
                                                            <a target="_blank" rel="noreferrer" href={instaFeed.permalink}>
                                                                <div className="ratio ratio-1x1 item-zoom">
                                                                    <img className="insta-feed" src={instaFeed.media_url} alt="insta-feed" />
                                                                </div>
                                                            </a>
                                                        </div>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 ms-5 text-end d-none d-lg-block">
                                    <svg className="close-menu" xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" onClick={closeMenu}>
                                        <path id="Path_14" data-name="Path 14" d="M41,0A41,41,0,1,1,0,41,41,41,0,0,1,41,0Z" transform="translate(82 82) rotate(180)" fill="#fff" />
                                        <g id="Group_64" data-name="Group 64" transform="translate(0.001 -0.118)">
                                            <path className="stroke-w" id="Path_57" data-name="Path 57" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(810.387 636.944) rotate(180)" fill="none" stroke="#4b77a6" strokeMiterlimit="10" strokeWidth="4" />
                                            <path className="stroke-w" id="Path_58" data-name="Path 58" d="M780.269,584.943l-10.882,10.882,10.882,10.882" transform="translate(-728.389 -554.708)" fill="none" stroke="#4b77a6" strokeMiterlimit="10" strokeWidth="4" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="nav-margin">
                {props.children}
            </div>

            <div className="container">
                <div className="black-bg" animate="up">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="d-flex align-items-center subscribe mb-lg-0 mb-2">
                                <img className="subscribe-icon mb-lg-2 mb-1" src="/assets/images/subscribe-icon.svg" alt="icon" />
                                <p className=" mb-0 ms-2">{props.generalData.websiteSettings.newsletter_title}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <form action="https://arabgen.us5.list-manage.com/subscribe/post?u=ac7cf4e1588659103de0fb8f4&amp;id=0c6d835cac" method="post" target="_blank" rel="noreferrer">
                                <div className="row g-0 align-items-center subscribe ">
                                    <div className="col-lg-9 col-md-8 col-sm-8 col-8">
                                        <input type="email" name="EMAIL" placeholder={props.generalData.websiteSettings.newsletter_email_placeholder} />
                                    </div>
                                    <div className="col-lg-3 col-md-2 col-sm-3 col-4">
                                        <button className="btn-submit">{props.generalData.websiteSettings.submit_button_placeholder}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer pt-5 no-transition-delay" animate="">
                <div className="container  pt-5 pb-3">
                    <div className="row pt-5">
                        <div className="col-lg-5 " animate="left">
                            <Link to="/">
                                <img className="mb-4 logo-footer" src={props.generalData.websiteSettings.footer_logo_path} alt="logo" />
                            </Link>
                            <p className="mb-lg-4 mb-1">{props.generalData.websiteSettings.footer_description}</p>
                            <a className="terms" href="/Terms">{props.generalData.websiteSettings.footer_terms_title}</a>
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-3 col-md-8 mt-5 col-sm-8 col-6" animate="right">
                            <h1 className="mb-4">{props.generalData.websiteSettings.footer_contact_title}</h1>
                            <div className="contact-footer " dangerouslySetInnerHTML={{ __html: props.generalData.websiteSettings.footer_contact_content }} />

                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-4 col-6 mt-5" animate="right">
                            <h1 className="mb-4">{props.generalData.websiteSettings.footer_social_title}</h1>
                            <div className="flex-wrap">

                                {
                                    props.generalData.socialChannels.map(icon => (
                                        <a target="_blank" rel="noreferrer" href={icon.link} key={icon.id}>
                                            <img className="social-icon me-3" src={icon.icon_path} alt="social-icon" />
                                        </a>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                    <hr className="my-4" />
                    {/* <p className="copy-right" animate="left">{props.generalData.websiteSettings.copyright}</p> */}
                    <div className="copy-right" dangerouslySetInnerHTML={{ __html: props.generalData.websiteSettings.copyright }} />
                </div>
            </div>

            <div className="position-relative">
                <div className="container ">
                    <div className="text-end">
                        <div className="sroll-top" onClick={() => animateScroll.scrollTo(0, 0)}>
                            <svg id="Group_169" data-name="Group 169" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 82 82">
                                <path id="Path_14" data-name="Path 14" d="M41,0A41,41,0,1,1,0,41,41,41,0,0,1,41,0Z" transform="translate(82) rotate(90)" fill="#9b4493" />
                                <g id="Group_19" data-name="Group 19" transform="translate(1419.996 -18.889) rotate(90)">
                                    <g id="Group_17" data-name="Group 17" transform="matrix(-1, 0.017, -0.017, -1, 68.621, 1395.724)">
                                        <path id="Path_10" data-name="Path 10" d="M786.265,584.943l-16.878,16.878L786.265,618.7" transform="translate(-769.387 -584.943)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="4" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}