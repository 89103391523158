import Layout from "../components/Layout";
import Header from "../components/Header";
import Banner from "../components/Banner";
import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";


export default function Publications(props) {
    const [publicationsDetails, setPublicationsDetails] = useState();
    const [publicationsSettings, setPublicationsSettings] = useState();

    useEffect(() => {
        window.triggerScroll();

        axios.post('publications').then(response => {
            setPublicationsDetails(response.data)
            window.triggerScroll();
        });

        axios.post('publications-settings').then(response => {
            setPublicationsSettings(response.data)
            window.triggerScroll();
        });
    }, []);
    
  
    return (

        <Layout generalData={props.generalData} setActiveAboutSection={props.setActiveAboutSection} activePage="resources">

            <Helmet>
                <title>{props.seoPage.title}</title>
                <meta name="title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta name="description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta property="og:description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="og:image" content={props.seoPage.image_full_path} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta property="twitter:description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="twitter:image" content={props.seoPage.image_full_path} />
            </Helmet>

            <Header
                title={props.generalData.publicationsSettings.page_title}
            />

            <Banner
                image={props.generalData.publicationsSettings.banner_image_path}
                title={props.generalData.publicationsSettings.banner_title}
            />
            {
                publicationsDetails ?

                    <div className="container py-4" >
                        <div className="row py-lg-5 pb-4" >
                            {
                                publicationsDetails.map(publication => (
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12 g-4 " animate="down" key={publication.id}>
                                        <div className="description shadow p-2">
                                            <div className="row gx-2">
                                                {
                                                    publication.featured_image ?
                                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 pb-2 pb-lg-0 pb-md-0 pb-sm-0">

                                                            <div className="ratio add-padding-tools" >
                                                                <img className="publication-image" src={publication.featured_image} alt="tools" />
                                                            </div>

                                                        </div>
                                                        :
                                                        null
                                                }
                                                {/* <div className={publication.featured_image ? "col-lg-8 col-6" : "col-lg-12" + " position-link pe-xl-5"}> */}
                                                <div className="col position-link pe-xl-5">
                                                    <h3 className="mb-0">{publication.title}</h3>
                                                    <h4 className="mb-0">{publication.date}</h4>
                                                    <div className="description-text" dangerouslySetInnerHTML={{ __html: publication.description }} />
                                                    <div className="d-flex download">
                                                        {
                                                            publication.pdf_file ?
                                                                <a href={publication.pdf_file} className="underlined-link" download>{props.generalData.publicationsSettings.download_button_label}</a>
                                                                :
                                                                <a href={publication.pdf_link} target="_blank" rel="noreferrer" className="underlined-link" download>{props.generalData.publicationsSettings.download_button_label}</a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    :
                    <div className="lds-ellipsis mx-auto">
                        <div></div>
                        <div></div>
                    </div>
            }
        </Layout>
    )
}
