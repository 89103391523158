import Select from "react-select";

export default function CustomSelect(props) {
    return (
        <Select
            value={props.value}
            options={props.options}
            onChange={props.onChange}
            classNamePrefix="custom-select"
            isMulti
            isClearable={false}
            styles={{

                menu: (provided, state) => ({
                    ...provided,
                    border: '2px solid #9A4792',
                    padding: '0 10px',
                    borderRadius: '8px',
                }),

                option: (provided, state) => ({
                    borderBottom: '2px dotted #9A4792',
                    color: state.isSelected ? '#ACACAC' : '#ACACAC',
                    padding: 16,
                    fontSize: '14px',
                    zIndex: '2000',
                    textTransform: 'capitalize',
                    cursor: "pointer"
                }),

                control: (provided, state) => ({
                    ...provided,
                    border: '2px solid #9A4792',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    zIndex: '3000',
                    padding: '10px 0px',
                    fontSize: '14px',
                }),

                indicatorsContainer: (provided, state) => ({
                    position: 'absolute',
                    top: '50%',
                    right: 10,
                    transition: 'all .5s',
                    transform: state.selectProps.menuIsOpen ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)',
                    zIndex: '3000',
                    padding: '30px 0px'
                }),

            }}
            components={{
                DropdownIndicator: () => (
                    <img className="arrow-filter" src="/assets/images/dropdown-arrow.svg" alt="arrow" />
                )
            }}
            placeholder={props.placeholder}
        />
    );
}