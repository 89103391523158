import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import BlogSection from "../components/BlogSection";
import { Helmet } from "react-helmet";


export default function Blog(props) {

    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    useEffect(() => {
        window.triggerScroll();
        setBlogs();

        axios.post('blog-posts?page=' + currentPage, {
            per_page: 12,
        }).then((r) => {
            setBlogs(r.data.data);
            setLastPage(r.data.last_page);
            window.triggerScroll();
        });
        window.triggerScroll();
    }, [currentPage]);

    return (
        <Layout generalData={props.generalData} setActiveAboutSection={props.setActiveAboutSection} activePage="blog">

            <Helmet>
                <title>{props.seoPage.title}</title>
                <meta name="title" content={props.seoPage.title} />
                {
                    props.seoPage.description ?
                        <meta name="description" content={props.seoPage.description} />
                        :
                        null
                }
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta property="og:description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="og:image" content={props.seoPage.image_full_path} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={props.seoPage.title} />
                {
                    props.seoPage.description ?
                        <meta property="twitter:description" content={props.seoPage.description} />
                        :
                        null
                }
                <meta property="twitter:image" content={props.seoPage.image_full_path} />
            </Helmet>

            <Header
                title={props.generalData.blogSettings.page_title}
            />

            <Banner
                image={props.generalData.blogSettings.banner_image_path}
                title={props.generalData.blogSettings.banner_title}
            />


            {
                blogs ?
                    <div className="py-lg-5">
                        <div className="container section-4 pt-5">
                            {
                                blogs.map(blog => (
                                    <BlogSection
                                        slug={blog.slug}
                                        image={blog.featured_image}
                                        title={blog.title}
                                        author={blog.author_name}
                                        credentials={blog.author.profile_url}
                                        description={blog.description}
                                        button={props.generalData.blogSettings.read_more_label}
                                    />
                                ))
                            }

                            {
                                currentPage !== 1 && (
                                    <button className="next-prev underlined-link" onClick={() => setCurrentPage(currentPage - 1)}>PREV</button>
                                )
                            }

                            {
                                currentPage !== lastPage && (
                                    <button className="next-prev underlined-link" onClick={() => setCurrentPage(currentPage + 1)}>NEXT</button>
                                )
                            }

                        </div>
                    </div>
                    :
                    <div className="lds-ellipsis mx-auto">
                        <div></div>
                        <div></div>
                    </div>
            }

        </Layout>
    )
}