import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import Banner from "../components/Banner";
import Header from "../components/Header";
import axios from "axios";
import { Helmet } from "react-helmet";

export default function PatientTools(props) {

    const [videoIdOpen, setVideoIdOpen] = useState(null);
    const [patientToolsPage, setPatientToolsPage] = useState([]);

    useEffect(() => {
        window.triggerScroll();

        axios.post('patient-tools-page').then(response => {
            setPatientToolsPage(response.data);
            window.triggerScroll();
        });
    }, []);

    function togglePlay(id) {
        setVideoIdOpen(id);
    }
    return (
        <Layout generalData={props.generalData} setActiveAboutSection={props.setActiveAboutSection} activePage="resources">

            <Helmet>
                <title>{props.seoPage.title}</title>
                <meta name="title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta name="description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location} />
                <meta property="og:title" content={props.seoPage.title} />
                {
                    props.seoPage.description ?
                        <meta property="og:description" content={props.seoPage.description} />
                        :
                        null
                }
                <meta property="og:image" content={props.seoPage.image_full_path} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location} />
                <meta property="twitter:title" content={props.seoPage.title} />
                {props.seoPage.description ?
                    <meta property="twitter:description" content={props.seoPage.description} />
                    :
                    null
                }
                <meta property="twitter:image" content={props.seoPage.image_full_path} />
            </Helmet>

            <Header
                title={props.generalData.patientToolsSettings.page_title}
            />

            <Banner
                image={props.generalData.patientToolsSettings.banner_image_path}
                title={props.generalData.patientToolsSettings.banner_title}
            />

            <div className="pt-5">


                {
                    patientToolsPage?.patientTools?.map(section => (
                        <div className="container ">
                            <div className="row">
                                <div className="col-lg-5 col-md-6 col-sm-6 col-10  title-section" animate="down">
                                    <h1 className="">{section.name}</h1>
                                    <div className="line-section"></div>
                                </div>
                            </div>
                            <div className="row pb-5" animate="up">
                                {
                                    section.patient.map(tool => (
                                        <div className="col-lg-4 col-md-12 col-sm-12 col-12 g-lg-5 g-md-5 g-sm-5 g-4">

                                            <div className="description shadow p-2">
                                                {
                                                    tool.video ?
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="ratio add-padding-video">
                                                                    {

                                                                        videoIdOpen === tool.id ?
                                                                            <video src={tool.video_path} autoPlay controls onEnded={() => togglePlay(null)} />
                                                                            :
                                                                            <>
                                                                                <img src={tool.image_path} alt="tools" />
                                                                                <img className="play-btn" src="/assets/images/play.svg" alt="" onClick={() => togglePlay(tool.id)} />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="row gx-lg-0 gx-2">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                                <div className="ratio add-padding-tools" >
                                                                    <img src={tool.image_path} alt="tools" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 position-link pe-4">
                                                                <h3 className="ms-lg-3 ms-2 mb-1">{tool.title}</h3>
                                                                <h4 className="ms-lg-3 ms-2 mb-1">{tool.date}</h4>
                                                                <p className="ms-lg-3 ms-2 mb-1">{tool.description}</p>
                                                                {
                                                                    tool.pdf_file_path ?
                                                                        <a href={tool.pdf_file_path} target="blank" className="underlined-link pdf ms-lg-3 ms-2" download>{props.generalData.patientToolsSettings.pdf_link_label}</a>
                                                                        :
                                                                        <a href={tool.pdf_link} target="blank" className="underlined-link pdf ms-lg-3 ms-2" download>{props.generalData.patientToolsSettings.pdf_link_label}</a>
                                                                }

                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    ))
                }
            </div>





        </Layout >


    )
}